import Flickity from "flickity";

const els = document.querySelectorAll(".HomeNews--list");
if (els.length) {
    const carousel = new Flickity(".HomeNews--list", {
        cellAlign: "left",
        prevNextButtons: true,
        pageDots: false,
        freeScroll: true,
        draggable: true,
        wrapAround: false,
        groupCells: "100%"
    });
}

// $(".HomeValues--buttons--prev").on("click", function () {
//     for (let k = 0; k < carouselArr.length; k++) {
//         carouselArr[k].previous();
//     }
// });
// // next
// $(".HomeValues--buttons--next").on("click", function () {
//     for (let k = 0; k < carouselArr.length; k++) {
//         carouselArr[k].next();
//     }
// });
