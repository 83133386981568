import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.create({
    trigger: ".HomeHero",
    start: "bottom top-100",
    end: 99999,
    onToggle: (self) => {
        // if (self.isActive) {
        //     $(".Burger,.Header--button-link").addClass("below-intro");
        // } else {
        //     $(".Burger,.Header--button-link").removeClass("below-intro");
        // }
    }
});
