(() => {
    var breakpoint;
    var breakpoint_refreshValue;
    breakpoint_refreshValue = function () {
        window.breakpoint = window
            .getComputedStyle(document.querySelector("body"), ":after")
            .getPropertyValue("content")
            .replace(/\"/g, "");
    };

    jQuery(window)
        .resize(function () {
            breakpoint_refreshValue();
        })
        .resize();
})();
