(function (window, $) {
    function init() {
        let permission = localStorage.getItem("wfPermission");
        if (permission == "TRUE") {
            $(".Agegate").removeClass("show");
        } else {
            $(".Agegate").addClass("show");
        }

        $(".Agegate--accept").click(function () {
            $(".Agegate").removeClass("show");
            localStorage.setItem("wfPermission", "TRUE");
        });
    }

    window.AgeGate = {
        init: init
    };
})(window, jQuery);

AgeGate.init();
