import { gsap, Back } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const settings = {
    isMenuOpen: false,
    elOpenButton: $(".Burger"),
    elOverlay: $(".Flyout--close-overlay"),
    elMenu: $(".Flyout")
};

class Burger {
    init() {
        this.setupBindings();
        this.createAnim();

        let st = ScrollTrigger.create({
            trigger: "body",
            pin: ".Header--right",
            start: "top top"
        });
    }

    createAnim() {
        this.tl = gsap.timeline({ paused: 0 });
        this.tl.from(".Flyout li", {
            x: 50,
            opacity: 0,
            duration: 0.2,
            stagger: 0.1,
            ease: "Back.easeOut(1.9)",
            onReverseComplete: this.hideEls
        });
    }

    setupBindings() {
        $(document).keyup((e) => {
            if (e.keyCode === 27 && settings.isMenuOpen) {
                this.closeMenu();
                settings.isMenuOpen = false;
            }
        });

        settings.elOpenButton.on("click", () => {
            if (!settings.isMenuOpen) {
                this.openMenu();
                settings.isMenuOpen = true;
            } else {
                this.closeMenu();
                settings.isMenuOpen = false;
            }
        });
    }

    toggleState() {
        settings.isMenuOpen = settings.isMenuOpen ? false : true;
        $("html").toggleClass("overlayIsOpen", settings.isMenuOpen);
        settings.elOpenButton.toggleClass("overlayIsOpen", settings.isMenuOpen);
    }

    openMenu() {
        settings.elMenu.show();
        // settings.elOverlay.addClass("show");
        settings.isMenuOpen = true;
        $("html").toggleClass("overlayIsOpen", true);
        settings.elOpenButton.toggleClass("overlayIsOpen", true);
        this.tl.play();
        // this.toggleState();
    }

    closeMenu() {
        $("html").toggleClass("overlayIsOpen", false);
        settings.elOpenButton.toggleClass("overlayIsOpen", false);
        // this.toggleState();
        this.tl.reverse();
    }

    hideEls() {
        settings.elMenu.hide();
        // settings.elOverlay.removeClass("show");
        settings.isMenuOpen = false;
    }
}

export { Burger };

const burger = new Burger();

burger.init();
