(function (window, $) {
    var settings = {
        map: null,
        $markers: null,
        mapStyle: [
            {
                featureType: "administrative",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#7f2200"
                    },
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "administrative",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        visibility: "on"
                    },
                    {
                        color: "#87ae79"
                    }
                ]
            },
            {
                featureType: "administrative",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#98b4a8"
                    }
                ]
            },
            {
                featureType: "administrative",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#ffffff"
                    },
                    {
                        visibility: "off"
                    },
                    {
                        weight: 4.1
                    }
                ]
            },
            {
                featureType: "administrative.country",
                elementType: "all",
                stylers: [
                    {
                        saturation: "30"
                    }
                ]
            },
            {
                featureType: "administrative.country",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        color: "#052629"
                    },
                    {
                        weight: "5.49"
                    }
                ]
            },
            {
                featureType: "administrative.country",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "administrative.province",
                elementType: "all",
                stylers: [
                    {
                        visibility: "on"
                    },
                    {
                        weight: "1"
                    },
                    {
                        gamma: "0.58"
                    },
                    {
                        color: "#4d6264"
                    }
                ]
            },
            {
                featureType: "administrative.province",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "administrative.province",
                elementType: "labels.text",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "administrative.locality",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "administrative.locality",
                elementType: "geometry",
                stylers: [
                    {
                        visibility: "on"
                    }
                ]
            },
            {
                featureType: "administrative.locality",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "administrative.locality",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        weight: "2.50"
                    },
                    {
                        color: "#98b4a8"
                    }
                ]
            },
            {
                featureType: "landscape",
                elementType: "all",
                stylers: [
                    {
                        saturation: "-30"
                    },
                    {
                        visibility: "on"
                    }
                ]
            },
            {
                featureType: "landscape",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "landscape",
                elementType: "geometry.fill",
                stylers: [
                    {
                        visibility: "on"
                    },
                    {
                        color: "#003337"
                    }
                ]
            },
            {
                featureType: "poi",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#052629"
                    }
                ]
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#98b4a8"
                    },
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "poi",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#98b4a8"
                    }
                ]
            },
            {
                featureType: "poi.attraction",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "poi.business",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "poi.government",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "poi.medical",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#052629"
                    }
                ]
            },
            {
                featureType: "poi.school",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [
                    {
                        visibility: "simplified"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#98b4a8"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#ABCE83"
                    },
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#EBF4A4"
                    },
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#3b6951"
                    },
                    {
                        weight: "1.60"
                    }
                ]
            },
            {
                featureType: "road.local",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#d8e4e5"
                    },
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "transit",
                elementType: "all",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        visibility: "on"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#052629"
                    },
                    {
                        visibility: "on"
                    },
                    {
                        weight: "1.00"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        visibility: "off"
                    },
                    {
                        color: "#ff0000"
                    }
                ]
            }
        ]
    };

    function init() {
        $(".acf-map").each(function () {
            var map = initMap($(this));
        });
    }

    function initMap($el) {
        var $markers = $el.find(".marker");

        // Create gerenic map.
        var mapArgs = {
            zoom: $el.data("zoom") || 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: settings.mapStyle,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false
        };
        var map = new google.maps.Map($el[0], mapArgs);

        // Add markers.
        map.markers = [];
        $markers.each(function () {
            initMarker($(this), map);
        });

        // Center map based on markers.
        centerMap(map);

        // Return map instance.
        return map;
    }

    function initMarker($marker, map) {
        // Get position from marker.
        var lat = $marker.data("lat");
        var lng = $marker.data("lng");
        var latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };

        // Create marker instance.
        var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon:
                window.stylesheet_directory_uri +
                "/dist/img/farm_garden_tree_icon.png"
        });

        // Append to reference for later use.
        map.markers.push(marker);

        // If marker contains HTML, add it to an infoWindow.
        if ($marker.html()) {
            // Create info window.
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, "click", function () {
                infowindow.open(map, marker);
            });
        }
    }

    function centerMap(map) {
        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function (marker) {
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });

        // Case: Single marker.
        if (map.markers.length == 1) {
            map.setCenter(bounds.getCenter());

            // Case: Multiple markers.
        } else {
            map.fitBounds(bounds);
        }
    }

    window.Maps = {
        init: init
    };
})(window, jQuery);

Maps.init();
