import { ScrollTrigger } from "gsap/ScrollTrigger";

const scrollSections = document.querySelectorAll(".HomeForrest--scroll-inner");

scrollSections.forEach((section, index) => {
    ScrollTrigger.create({
        trigger: section,
        start: "top bottom-=85%",
        end: "bottom 50%+=100px",
        onToggle: (self) => {
            if (self.isActive) {
                $(".HomeForrest--background").removeClass("show");
                $(".HomeForrest--background-" + index).addClass("show");

                $(".HomeForrest--title").removeClass("active");
                $(".HomeForrest--footer li").removeClass("active");
                $(
                    ".HomeForrest--title:nth-child(" + (index + 1) + ")"
                ).addClass("active");
                $(
                    ".HomeForrest--footer li:nth-child(" + (index + 1) + ")"
                ).addClass("active");
            }
        }
    });
});

ScrollTrigger.create({
    trigger: ".HomeForrest",
    start: "top top",
    end: "bottom bottom+=80",
    onToggle: (self) => {
        if (self.isActive) {
            $(".HomeForrest--footer").addClass("sticky");
        } else {
            $(".HomeForrest--footer").removeClass("sticky");
        }
    }
});
